import React, { Fragment, useState, useEffect } from 'react';
import styled, { css } from 'styled-components';

import HeaderBar from 'components/header-bar';
import CouponCard from 'components/coupon-card';
import ModalDetail from 'pages/mycoupon/ModalDetail';
import ModalCouponRule from './ModalCouponRule';
import { useAPI } from 'utils/api';
import { callAppFunc } from 'utils/jsbridge';
import ContentLoader from 'react-content-loader';
import IconEmpty from 'images/icons/empty.svg';

const UsageBtn = styled.button`
  font-size: 14px;
  font-weight: 400;
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  border: 1px solid #e3e3e3;
  background-color: #fff;
  width: 88px;
  height: 30px;
  padding: 0;
`;

const PageContent = styled.div`
  padding: 70px 16px 0;
  height: calc(100% - 70px);
`;

const CouponListWrapper = styled.div`
  overflow-y: scroll;
  height: 100%;
  padding: 2px;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const CouponList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px 0;
  width: 100%;
`;

const Empty = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 43px;
`;

const EmptySubtitle = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: #3b3516;
  margin-top: 8px;
`;

const CouponTicketPreview = () => {
  const viewWidth = document.body.clientWidth;
  const mainWidth = 390;
  const ratio = viewWidth / mainWidth;
  return (
    <>
      <ContentLoader
        viewBox={`0 0 ${358 * ratio} 1000`}
        height={1000}
        width={358 * ratio}
      >
        {[...Array(5)].map((i, index) => {
          return (
            <>
              {[...Array(1)].map((j, subIndex) => {
                return (
                  <>
                    <rect
                      x={subIndex * 127 * ratio}
                      y={20 + 140 * index}
                      rx="8"
                      ry="8"
                      width={360 * ratio}
                      height="120"
                    />
                  </>
                );
              })}
            </>
          );
        })}
      </ContentLoader>
    </>
  );
};

const Coupon = (props) => {
  const [showModalDetail, setShowModalDetail] = useState(false);
  const [showModalUsage, setShowModalUsage] = useState(false);
  const [couponList, setCouponList] = useState([]);
  const [modalDetailText, setModalDetailText] = useState('');
  const [usageList, setUsageList] = useState([]);
  const [isReceive, setIsReceive] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [couponRule, setCouponRule] = useState('');
  const [isPageInit, setIsPageInit] = useState(false);
  const api = useAPI();

  /* const [status, setStatus] = useState(0); */

  const closeModalDetail = () => {
    setShowModalDetail(false);
  };

  const closeModalUsage = () => {
    setShowModalUsage(false);
  };

  const getUsage = () => {
    api
      .getUsageList()
      .then((res) => {
        console.log(res);
        if (res) {
          setUsageList(res);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getCouponList = () => {
    setIsLoading(true);
    api
      .getCouponList()
      .then((res) => {
        console.log(res);
        setIsLoading(false);
        setCouponList(res);
        setIsPageInit(true);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  useEffect(() => {
    getCouponList();
    getUsage();
    api
      .getCouponRule()
      .then((res) => {
        if (!res) {
          return;
        }
        setCouponRule(res.content);
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    if (isPageInit) {
      setIsPageInit(false);
    }
  }, [isPageInit]);

  return (
    <Fragment>
      <HeaderBar
        title="折價券中心"
        fixed
        extra={
          <UsageBtn onClick={() => setShowModalUsage(true)}>使用辦法</UsageBtn>
        }
        goBack={() => callAppFunc('backHome', {})}
      />
      <PageContent>
        {isLoading ? (
          <CouponTicketPreview></CouponTicketPreview>
        ) : couponList.length > 0 ? (
          <CouponListWrapper>
            <CouponList>
              {couponList?.map((coupon, idx) => {
                return (
                  <CouponCard
                    key={idx}
                    coupon={coupon}
                    showTicketDetail={(text) => {
                      setModalDetailText(text);
                      setShowModalDetail(true);
                    }}
                  />
                );
              })}
            </CouponList>
          </CouponListWrapper>
        ) : couponList.length === 0 &&
          isLoading !== true &&
          isPageInit !== true ? (
          <Empty>
            <img src={IconEmpty} />
            <EmptySubtitle>目前無折價卷</EmptySubtitle>
          </Empty>
        ) : undefined}
      </PageContent>

      <ModalDetail
        text={modalDetailText}
        isOpen={showModalDetail}
        onOk={closeModalDetail}
      />

      <ModalCouponRule
        content={couponRule}
        isOpen={showModalUsage}
        onCancel={closeModalUsage}
      />
    </Fragment>
  );
};

export default Coupon;
